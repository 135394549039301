import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/packages/website/src/components/DocsLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`React Ensemble executes regions `}<em parentName="p">{`sequentially`}</em>{`, meaning one region won't start until the one before it has finished.
If we were to configure a region to start when another region was still running, `}<inlineCode parentName="p">{`TrackUtils.gen`}</inlineCode>{` would throw an error.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "510px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c61ee4d9dc94ed064a7a65a885231a2d/0abdd/track-diagram.drawio.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "34%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsSAAALEgHS3X78AAABaUlEQVQoz62PP0/CUBTFXxz9AiauJn4bB2yhLaWFNoVEa+IiCfoJnFxdjEKEQTeNAUyUhUQHUyUKFBT/QFQESV+7EZ70+Voqyu5LfjnnnnNzkwdgVbWMimoQICSYjlZV6MymrsLHixgsFxT4ebs8zuEkBsl7sKz2e9fLC6C+J+F6ipCUsE70LkOUZDqZa2TWtkVc3OKxvhtx92rJUfeXyk4YN49i+ENbosALw+CGwAybIms3Qoxd4vz2e4SzX8Mj2hJnd+Sg2z0JjP0ssG7+5kH8sB3ivhorPC7l5EXwwASGRT810PkAaoVZdM8zyIzyyFB4BJVfvWH96DJAoSrZ68pBZHodYWDJfL9JDl4dSz5gxSUMNyRsrUu4kyDfigvYJB4mJnF6SDA9/ckNx69FcHdTwXohyoKzQ9GXTwt0dj9Enx+ItHYquz6fniTrac5j3GUE+iTFU1peZnBrdRYAMDcP/udNATAz/Q2qtz10t1u3DwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Track Diagram",
            "title": "Track Diagram",
            "src": "/static/c61ee4d9dc94ed064a7a65a885231a2d/0abdd/track-diagram.drawio.png",
            "srcSet": ["/static/c61ee4d9dc94ed064a7a65a885231a2d/5a46d/track-diagram.drawio.png 300w", "/static/c61ee4d9dc94ed064a7a65a885231a2d/0abdd/track-diagram.drawio.png 510w"],
            "sizes": "(max-width: 510px) 100vw, 510px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`So what about if we want to have two regions overlap? For instance, what if we want regions one and two to start at the same time?`}</p>
    <p>{`For executing regions `}<em parentName="p">{`concurrently`}</em>{`, React Ensemble offers the concept of `}<strong parentName="p">{`track layers`}</strong>{`.
A layer is essentially a sub-track for regions to be placed in so they can run at the same time as other sub-tracks (layers).`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "390px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d7f21947621a4472c20b658a4c8dbe62/727ba/layer-multi-diagram.drawio.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "77.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsSAAALEgHS3X78AAACrklEQVQ4y6XS/08SYRwH8Gfjh7bW6t/o9/6Rlk6eu3sODsLgjmwG2qYuqbVcmxmKf0Hzh5q1NSc/VGtLGIp8xyS0bBNE5I4DIsPaOLlPd1gLzTZan+2153Ofe+/zy/OgSlLIVpNCpRh2SDshe5sc5/dqKeF7Oc4PI63kBG9A3ZacEiq7cR7CCxbwPyYQesHBh2A/fNsahFpauKtnqmmh+4XpOU5KDWDYcNOtrJtWs0O0mhmglMw9E2y8sXn0TPIJ1/3C2EMilRgKShZKLXHayVFq3cIoBScLkafm9sLgFN39wuQ0K26zGF4brxz6ey63VpneVt5CNfODLCSeceN6ZsVHDHJCQN1AUS/Zr5oJNBwm+HyNhX07Cw0bC+JNDuLPufv6wvV5yxkx6jCIMd6gX5B2kZpf53Eou3A1GLyNs8vj1Loms+yhMqExvBb2knxuxW5F/1q1d8JENeucrWSdMxrfTzO1985ZOco/KEf58VpC8Mgx3iOtOjxShPeUNdKf7pRj/CjSnkZDLbiguT0ISu43/Rv2XFDfuAHJV/3wMcSDmL4OUHRp3B2nZlfrxSE42BwAtPXSVkr4WEj7WCXlYw876bPYI6IEJihlZZJRYl6iZ07TTHhJKzNvqaPIFCN96umDAsFqnsZwDGWEIoOhbKJAZCkoEgw7JzOaHIPVkhHDqgs3UGKaSKKVhpqTqBWegU5VTYT0QoDqgTDTC2lzH4h2uj3vzMkCo361E0iNUA0UnWRKRQpD3cY0q1Za6VTTFExYyeA+ZYsxKnkWK7LlaN6Zq1jp5r6ZUeNu/AWtzXEH28Mm2B0xQ+EUxVEziGMc7I1y7f60zI6eu2WGpJcFtBnoZwN+jgv6OUZDTtL+kbeLHFlaPOqDf7Hk59hczEHpT/EsQhcvIXT+Avr/OvcDK2B4ZLdTCm0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Layer Diagram",
            "title": "Layer Diagram",
            "src": "/static/d7f21947621a4472c20b658a4c8dbe62/727ba/layer-multi-diagram.drawio.png",
            "srcSet": ["/static/d7f21947621a4472c20b658a4c8dbe62/5a46d/layer-multi-diagram.drawio.png 300w", "/static/d7f21947621a4472c20b658a4c8dbe62/727ba/layer-multi-diagram.drawio.png 390w"],
            "sizes": "(max-width: 390px) 100vw, 390px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Layers are executed independently of one another. Therefore, if two regions are put on separate layers, they will run at the same time.`}</p>
    <h2 {...{
      "id": "setting-layers"
    }}>{`Setting Layers`}</h2>
    <p>{`Set a region's layer by specifying a string as its `}<inlineCode parentName="p">{`layer`}</inlineCode>{` property. This string is the layer's `}<strong parentName="p">{`name`}</strong>{`. Any other regions within the track that have the same layer name will be put on the same layer.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const track = [
  {
    layer: "1_movement", // Set layer
    duration: 5000,
    state: { x: { to: 10 } }
  },
  {
    // No layer prop: will be put in default layer
    start: 2500,
    duration: 1000,
    state: { y: { to: "#AAAAAA" } }
  },
  {
    layer: "1_movement", // Will start after the previous region in this layer (5 seconds)
    duration: 2000,
    state: { x: { to: 5 } }
  }
];
`}</code></pre>
    <p>{`If it's easier, you can use `}<a parentName="p" {...{
        "href": "/docs/api/trackutils#layer"
      }}><inlineCode parentName="a">{`TrackUtils.layer`}</inlineCode></a>{` to set one or more regions' layers.
This function can take a string or a number as the layer name, which will be cast to a string.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { TrackUtils } from "react-ensemble";

const myRegions = TrackUtils.layer(1, [
  { duration: 5000, state: { x: { to: 10 } } },
  { duration: 2000, state: { x: { to: 5 } } }
]); // Returns copies of these regions where layer = "1"
`}</code></pre>
    <h2 {...{
      "id": "working-in-multiple-layers"
    }}>{`Working in Multiple Layers`}</h2>
    <p>{`Since layers are independent of each other, it can be easier to visualize multiple layers by using the helper function `}<a parentName="p" {...{
        "href": "/docs/api/trackutils#multi"
      }}><inlineCode parentName="a">{`TrackUtils.multi`}</inlineCode></a>{`.`}</p>
    <p>{`With `}<inlineCode parentName="p">{`multi`}</inlineCode>{`, you can pass in layers as separate arrays or regions and they'll be converted into a regular track with layer names (based on each sub-track's index).`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const track = TrackUtils.multi([
  [
    { duration: 5000, state: { x: { to: 10 } } }, // Will be assigned layer = "0"
    { duration: 2000, state: { x: { to: 5 } } } // Will be assigned layer = "0"
  ],
  { start: 500, duration: 0, state: { y: { set: 100 } } } // Will be assigned layer = "1"
]);
`}</code></pre>
    <p>{`Alternatively, you can pass `}<inlineCode parentName="p">{`multi`}</inlineCode>{` an object with each value being one or more regions. Layers will be assigned based on each key.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const track = TrackUtils.multi({
  foo: [
    { duration: 5000, state: { x: { to: 10 } } }, // Will be assigned layer = "foo"
    { duration: 2000, state: { x: { to: 5 } } } // Will be assigned layer = "foo"
  ],
  bar: { start: 500, duration: 0, state: { y: { set: 100 } } } // Will be assigned layer = "bar"
});
`}</code></pre>
    <p>{`Keep in mind, `}<inlineCode parentName="p">{`multi`}</inlineCode>{` is just nice syntax.
You can use all the features of layering by configuring the right properties on your track regions.
All `}<inlineCode parentName="p">{`multi`}</inlineCode>{` does is add them for you.`}</p>
    <p>{`For example, that last call to `}<inlineCode parentName="p">{`multi`}</inlineCode>{` outputs this region, which is called a `}<strong parentName="p">{`group`}</strong>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const track = [
  {
    regions: [
      { layer: "foo", duration: 5000, state: { x: { to: 10 } } },
      { layer: "foo", duration: 2000, state: { x: { to: 5 } } },
      { layer: "bar", start: 500, duration: 0, state: { y: { set: 100 } } }
    ]
  }
];
`}</code></pre>
    <p>{`Skip to the `}<a parentName="p" {...{
        "href": "/docs/guides/groups"
      }}>{`Groups guide`}</a>{` to learn more about groups.`}</p>
    <h2 {...{
      "id": "layer-resolution"
    }}>{`Layer Resolution`}</h2>
    <p>{`It's important to note that every layer is essentially its own animation, meaning each layer has a potential value for every state property at any given frame.`}</p>
    <p>{`In other words, if your animation has state `}<inlineCode parentName="p">{`{ x: number, y: string }`}</inlineCode>{` and multiple layers, `}<em parentName="p">{`every layer`}</em>{` will have its own value for `}<inlineCode parentName="p">{`x`}</inlineCode>{` and `}<inlineCode parentName="p">{`y`}</inlineCode>{` on each frame.
Obviously, we can only draw one `}<inlineCode parentName="p">{`x`}</inlineCode>{` to the screen, so how do we decide which layer's `}<inlineCode parentName="p">{`x`}</inlineCode>{` to use?`}</p>
    <p>{`To solve this problem, React Ensemble uses a `}<strong parentName="p">{`layer resolver`}</strong>{`.
This is a function that takes information about all the layers using a state property and decides which value is the right one to draw.`}</p>
    <h3 {...{
      "id": "a-practical-example"
    }}>{`A Practical Example`}</h3>
    <p>{`Consider the following track:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`[
  { layer: "1", duration: 0, state: { x: { set: 123 } } },
  { layer: "2", start: 500, duration: 0, state: { x: { set: 99 } } }
];
`}</code></pre>
    <p>{`This animation has two layers: 1 and 2. If `}<inlineCode parentName="p">{`x`}</inlineCode>{` has a default value of `}<inlineCode parentName="p">{`0`}</inlineCode>{`, the following is true:`}</p>
    <ul>
      <li parentName="ul">{`At time = 0, `}<inlineCode parentName="li">{`x`}</inlineCode>{` is changed from `}<inlineCode parentName="li">{`0`}</inlineCode>{` to `}<inlineCode parentName="li">{`123`}</inlineCode>{` on layer 1`}</li>
      <li parentName="ul">{`At time = 500, `}<inlineCode parentName="li">{`x`}</inlineCode>{` is changed from `}<inlineCode parentName="li">{`0`}</inlineCode>{` to `}<inlineCode parentName="li">{`99`}</inlineCode>{` on layer 2`}</li>
    </ul>
    <p>{`Since we have two layers, there are two possible `}<inlineCode parentName="p">{`x`}</inlineCode>{` values we could draw at any frame of the animation.
Let's consider some possible ways to resolve this.`}</p>
    <p><strong parentName="p">{`First Option: Prioritize the first layer`}</strong></p>
    <p>{`With this resolver, we always choose the `}<inlineCode parentName="p">{`x`}</inlineCode>{` value from the first layer. Our animation would look like this:`}</p>
    <ul>
      <li parentName="ul">{`At time = 0, `}<inlineCode parentName="li">{`x`}</inlineCode>{` is changed from `}<inlineCode parentName="li">{`0`}</inlineCode>{` to `}<inlineCode parentName="li">{`123`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`x`}</inlineCode>{` remains `}<inlineCode parentName="li">{`123`}</inlineCode>{` for the rest of the animation`}</li>
    </ul>
    <p>{`The information from layer 2 is lost. This probably isn't the best option.`}</p>
    <p><strong parentName="p">{`Second Option: Prioritize the last layer`}</strong></p>
    <p>{`If we always choose the `}<inlineCode parentName="p">{`x`}</inlineCode>{` from the last layer instead, our animation would look like this:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`x`}</inlineCode>{` is `}<inlineCode parentName="li">{`0`}</inlineCode>{` until time = 500`}</li>
      <li parentName="ul">{`At time = 500, `}<inlineCode parentName="li">{`x`}</inlineCode>{` is changed from `}<inlineCode parentName="li">{`0`}</inlineCode>{` to `}<inlineCode parentName="li">{`99`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`x`}</inlineCode>{` remains `}<inlineCode parentName="li">{`99`}</inlineCode>{` for the rest of the animation`}</li>
    </ul>
    <p>{`Just like in the first option, we're losing the information from one of the layers.`}</p>
    <p><strong parentName="p">{`Third Option: Choose the value that's changed most recently`}</strong></p>
    <p>{`With this resolver, we choose the `}<inlineCode parentName="p">{`x`}</inlineCode>{` value that has changed most recently. Here's how that would look:`}</p>
    <ul>
      <li parentName="ul">{`At time = 0, `}<inlineCode parentName="li">{`x`}</inlineCode>{` is changed from `}<inlineCode parentName="li">{`0`}</inlineCode>{` to `}<inlineCode parentName="li">{`123`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`x`}</inlineCode>{` is `}<inlineCode parentName="li">{`123`}</inlineCode>{` until time = 500`}</li>
      <li parentName="ul">{`At time = 500, `}<inlineCode parentName="li">{`x`}</inlineCode>{` is changed from `}<inlineCode parentName="li">{`123`}</inlineCode>{` to `}<inlineCode parentName="li">{`99`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`x`}</inlineCode>{` remains `}<inlineCode parentName="li">{`99`}</inlineCode>{` for the rest of the animation`}</li>
    </ul>
    <p>{`This is similar to how React Ensemble's default layer resolver works, as you'll see in the next section.`}</p>
    <h3 {...{
      "id": "the-default-layer-resolver"
    }}>{`The Default Layer Resolver`}</h3>
    <p>{`React Ensemble's default layer resolver is `}<strong parentName="p">{`overrideLast`}</strong>{`, accessible at `}<a parentName="p" {...{
        "href": "/docs/api/trackutils#layerresolversoverridelast"
      }}><inlineCode parentName="a">{`TrackUtils.layerResolvers.overrideLast`}</inlineCode></a></p>
    <p>{`As hinted by its name, the `}<strong parentName="p">{`overrideLast`}</strong>{` resolver selects the value candidate with the lowest age (least amount of time since it was last updated).`}</p>
    <p>{`If multiple candidates have the same age, the one with the highest layer rank will be used.
Layer rank is calculated by alphanumerically sorting all layer names that exist in the track.
In other words, if there's a tie, a layer named `}<inlineCode parentName="p">{`10`}</inlineCode>{` will beat a layer named `}<inlineCode parentName="p">{`9`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "writing-a-resolver"
    }}>{`Writing a Resolver`}</h3>
    <p>{`You can override the layer resolver for an animation by setting the `}<inlineCode parentName="p">{`resolver`}</inlineCode>{` prop in your `}<inlineCode parentName="p">{`Timeline`}</inlineCode>{`.`}</p>
    <p>{`A layer resolver must match the following signature:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`<T>(
  stateKey: string,
  layers: Array<{ name: string; rank: number; age: number; value: T }>
) => T;
`}</code></pre>
    <p><inlineCode parentName="p">{`stateKey`}</inlineCode>{` is the name of the state property being evaluated, and `}<inlineCode parentName="p">{`layers`}</inlineCode>{` contains information about the property's use in every layer.`}</p>
    <p>{`For example, let's write a resolver that returns the `}<strong parentName="p">{`sum`}</strong>{` of all instances of `}<inlineCode parentName="p">{`x`}</inlineCode>{` across all layers.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const sumXResolver = (stateKey, layers) => {
  if (stateKey !== "x") {
    // Use the default resolver if it's a different state property
    return TrackUtils.layerResolvers.overrideLast(stateKey, layers);
  }

  const total = 0;
  layers.forEach(({ value }) => (total += value));

  return total;
};
`}</code></pre>
    <h2 {...{
      "id": "further-reading"
    }}>{`Further Reading`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/api/trackutils"
        }}>{`TrackUtils API`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/api/types#tracklayerresolver"
        }}>{`TrackLayerResolver API`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      